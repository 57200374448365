import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from '../utils/axios';
import { resetPartner, setPartner, setSession } from '../utils/jwt';
import { API_ROUTES } from 'src/routes/api';
import { PATH_AUTH } from 'src/routes/paths';

// ----------------------------------------------------------------------

export const ROLES = {
  staff: 0,
  owner: 1,
  manager: 2,
  super_user: 3
};

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  partners: null,
  isPartnersLoading: false,
  activePartner: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  GET_PARTNERS_START: (state) => ({
    ...state,
    isPartnersLoading: true
  }),
  GET_PARTNERS_SUCCESS: (state, action) => ({
    ...state,
    isPartnersLoading: false,
    partners: action.payload.partners
  }),
  SET_ACTIVE_PARTNER: (state, action) => ({
    ...state,
    activePartner: action.payload.partner
  }),
  TOGGLE_PARTNER_FLAG: (state) => ({
    ...state,
    user: {
      ...state.user,
      hasPartnerYachtsWithoutOnlineBookingAvailable: false
    }
  })
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (email, password) => {
    const { headers } = await axios.post(API_ROUTES.auth.singIn, {
      email,
      password
    });

    const accessToken = headers['access-token'];
    const client = headers['client'];
    const uid = headers['uid'];

    setSession(accessToken, client, uid);

    const { data } = await axios.get(API_ROUTES.users.profile);

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          ...data?.user,
          hasPartnerYachtsWithoutOnlineBookingAvailable: data?.hasPartnerYachtsWithoutOnlineBookingAvailable,
          role: ROLES[data?.user?.role] || ROLES.staff
        }
      }
    });
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const resetPassword = async (email) => {
    window.localStorage.removeItem('accessToken');

    await axios.post(API_ROUTES.auth.password, {
      email,
      redirect_url: `${window.location.origin}${PATH_AUTH.newPassword}`
    });
  };

  const sendNewPassword = async (payload) => {
    let params = new URL(document.location).searchParams;

    const uid = params.get('uid');
    const client = params.get('client');
    const accessToken = params.get('access-token');

    const oldToken = window.localStorage.getItem('accessToken');

    if (!oldToken) {
      window.localStorage.setItem('accessToken', accessToken);
    }

    await axios.put(API_ROUTES.auth.password, payload, {
      headers: {
        uid,
        client
      }
    });
  };

  const updateProfile = () => {};

  useEffect(() => {
    const initialize = async () => {
      try {
        axios.interceptors.response.use(
          (response) => {
            return response;
          },
          (error) => {
            if (error.response?.status === 401) {
              logout();
            }

            return Promise.reject(error);
          }
        );

        const accessToken = localStorage.getItem('accessToken');
        const client = localStorage.getItem('client');
        const uid = localStorage.getItem('uid');
        const partner = localStorage.getItem('partner');

        if (accessToken && client && uid) {
          setSession(accessToken, client, uid);

          const { data } = await axios.get(API_ROUTES.users.profile);

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: {
                ...data?.user,
                hasPartnerYachtsWithoutOnlineBookingAvailable: data?.hasPartnerYachtsWithoutOnlineBookingAvailable,
                role: ROLES[data?.user?.role] || ROLES.staff
              }
            }
          });

          if (partner) {
            console.log('@@@ super user as partner: ', partner);
            setActivePartner(JSON.parse(partner));
          }
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const togglePartnerFlag = () => {
    dispatch({ type: 'TOGGLE_PARTNER_FLAG' });
  };

  const getPartnersForSuperUser = async (query) => {
    dispatch({ type: 'GET_PARTNERS_START' });
    try {
      const {
        data: { partners }
      } = await axios.get(API_ROUTES.partners.search(query));

      dispatch({ type: 'GET_PARTNERS_SUCCESS', payload: { partners } });
    } catch (error) {}
  };

  const setActivePartner = (partner) => {
    setPartner(partner);
    dispatch({ type: 'SET_ACTIVE_PARTNER', payload: { partner } });
  };

  const resetActivePartner = () => {
    resetPartner();
    dispatch({ type: 'SET_ACTIVE_PARTNER', payload: { partner: null } });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        sendNewPassword,
        updateProfile,
        togglePartnerFlag,
        getPartnersForSuperUser,
        setActivePartner,
        resetActivePartner
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
