import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { API_ROUTES } from 'src/routes/api';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  clientsList: {
    data: [],
    pagination: {}
  },
  activeFilters: {}
};

const slice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET YACHTS
    getClientsSuccess(state, action) {
      state.isLoading = false;
      state.clientsList = {
        data: action.payload.clients,
        pagination: action.payload.meta.pagination
      };
    },

    setActiveFiltersValue(state, action) {
      state.activeFilters = action.payload;
    },

    resetClients(state) {
      state.activeFilters = {};
      state.clientsList.data = [];
      state.clientsList.pagination = {};
    },

    reset(state) {
      Object.keys(state).forEach((key) => (state[key] = initialState[key]));
    }
  }
});

// Reducer
export default slice.reducer;

export const { resetClients, setActiveFiltersValue } = slice.actions;

// ----------------------------------------------------------------------

export function getClientsList(page = 1, page_size = 5, filters) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());

    const state = getState();

    const activeFilters = filters || state.clients.activeFilters;

    try {
      const {
        data: { clients, meta }
      } = await axios.get(API_ROUTES.clients.list, {
        params: {
          page,
          page_size,
          ...activeFilters
        }
      });
      dispatch(slice.actions.getClientsSuccess({ clients, meta }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function refetchClientsList() {
  return async (dispatch, getState) => {
    const { clients } = getState();
    const { currentPage, pageSize } = clients.clientsList.pagination;

    dispatch(getClientsList(currentPage, pageSize));
  };
}

export function setActiveFilters(activeFilters) {
  return async (dispatch, getState) => {
    const { clients } = getState();
    const { pageSize } = clients.clientsList.pagination;

    dispatch(slice.actions.setActiveFiltersValue(activeFilters));

    await dispatch(getClientsList(1, pageSize, activeFilters));
  };
}

export function reset() {
  return async (dispatch) => {
    dispatch(slice.actions.reset());
  };
}
