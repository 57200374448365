import { createSlice } from "@reduxjs/toolkit";

// ----------------------------------------------------------------------

const initialState = {
  isOpenSidebar: false,
};

const slice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setIsOpenSidebar(state, action) {
      state.isOpenSidebar = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setIsOpenSidebar } = slice.actions;
