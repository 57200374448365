import { createSlice } from '@reduxjs/toolkit';

import { API_ROUTES } from 'src/routes/api';
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  documentTemplates: {
    data: [],
    pagination: {}
  },
  document: {
    isLoading: false,
    id: '',
    data: {}
  },
  activeFilters: {}
};

const slice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getTemplatesSuccess(state, action) {
      state.isLoading = false;
      state.documentTemplates = {
        data: action.payload.documentTemplates,
        pagination: action.payload.meta.pagination
      };
    },

    setActiveFilters(state, action) {
      state.activeFilters = action.payload;
    },

    resetTemplates(state) {
      state.activeFilters = {};
      state.documentTemplates.data = [];
      state.documentTemplates.pagination = {};
    },

    startLoadingDocument(state) {
      state.document.isLoading = true;
    },

    getDocumentSuccess(state, action) {
      state.document = {
        isLoading: false,
        id: action.payload.id,
        data: action.payload.data
      };
    },

    clearDocument(state) {
      state.document = {
        id: '',
        isLoading: false,
        data: {}
      };
    }
  }
});

// Reducer
export default slice.reducer;

export const { resetTemplates, clearDocument } = slice.actions;

// ----------------------------------------------------------------------

export function getDocumentTemplates(page = 1, page_size = 10, filters) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());

    const state = getState();

    const activeFilters = filters || state.templates.activeFilters;

    try {
      const {
        data: { documentTemplates, meta }
      } = await axios.get(API_ROUTES.templates.index, {
        params: {
          page,
          page_size,
          ...activeFilters
        }
      });

      dispatch(slice.actions.getTemplatesSuccess({ documentTemplates, meta }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function refetchDocumentTemplates() {
  return async (dispatch, getState) => {
    const { templates } = getState();
    const { currentPage, pageSize } = templates.documentTemplates.pagination;

    dispatch(getDocumentTemplates(currentPage, pageSize));
  };
}

export function setActiveFilters(activeFilters) {
  return async (dispatch, getState) => {
    const { templates } = getState();
    const { pageSize } = templates.documentTemplates.pagination;

    dispatch(slice.actions.setActiveFilters(activeFilters));

    await dispatch(getDocumentTemplates(1, pageSize, activeFilters));
  };
}

export function getDocumentTemplate(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingDocument());

    try {
      const {
        data: { documentTemplate }
      } = await axios.get(API_ROUTES.templates.by_index(id));

      dispatch(
        slice.actions.getDocumentSuccess({
          id,
          data: documentTemplate
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
