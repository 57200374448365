import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { API_ROUTES } from 'src/routes/api';
import { snakeCase } from 'lodash';

// ----------------------------------------------------------------------

export const MODAL_TYPE = {
  PRICE_LIST: 'PRICE_LIST',
  MARINA: 'MARINA',
  LABELS: 'LABELS'
};

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  formConfig: null,
  specification: {
    isLoading: false,
    error: false,
    data: {}
  }
};

const slice = createSlice({
  name: 'yachtForm',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET DATA
    getYachtFormConfig(state, action) {
      state.isLoading = false;
      state.formConfig = action.payload;
    },

    // START LOADING
    startLoadingSpecification(state) {
      state.specification.isLoading = true;
      state.specification.error = false;
    },

    // HAS ERROR
    hasErrorSpecification(state, action) {
      state.specification.isLoading = false;
      state.specification.error = action.payload;
    },

    // GET DATA
    getYachtSpecification(state, action) {
      state.isLoading = false;
      state.specification.data = action.payload;
    },

    reset(state) {
      Object.keys(state).forEach((key) => (state[key] = initialState[key]));
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const { setActiveModal } = slice.actions;

// ----------------------------------------------------------------------

export function getYachtFormConfig() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const { data } = await axios.get(API_ROUTES.yachts.new);
      dispatch(slice.actions.getYachtFormConfig(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getYachtSpecification(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingSpecification());

    try {
      const { data } = await axios.get(API_ROUTES.yachtTypes.specification(id));

      const specification = {};
      Object.entries(data.yachtSpecification).forEach(([key, val]) => {
        if (val) {
          specification[snakeCase(key)] = val?.value || val;
        }
      });

      dispatch(slice.actions.getYachtSpecification(specification));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasErrorSpecification(error));
    }
  };
}

export function reset() {
  return async (dispatch) => {
    dispatch(slice.actions.reset());
  };
}
