import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { API_ROUTES } from 'src/routes/api';
import { updateYachtMainImage, updateYachtImageType } from 'src/redux/slices/yachts';

// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  isUploading: false,
  error: false,
  images: []
};

const slice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET DATA
    getImages(state, action) {
      state.isLoading = false;
      state.images = action.payload;
    },

    startUploading(state) {
      state.isUploading = true;
      state.isLoading = true;
      state.error = false;
    },

    successUpload(state, action) {
      state.isUploading = false;
      state.isLoading = false;
      state.error = false;
      state.images = action.payload;
    },

    imageError(state, action) {
      state.error = action.payload;
    },

    deleteImageSuccess(state, action) {
      state.images = action.payload;
    },

    setAsMainImageSuccess(state, action) {
      state.images = action.payload;
    },

    updateImages(state, action) {
      state.images = action.payload;
    },

    clearImages(state) {
      state.images = [];
    },

    reset(state) {
      Object.keys(state).forEach((key) => (state[key] = initialState[key]));
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function cloneImages(images) {
  return async (dispatch) => {
    dispatch(slice.actions.getImages(images));
  };
}

export function getImages(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const {
        data: { yachtImages }
      } = await axios.get(API_ROUTES.yachts.gallery(id));
      dispatch(slice.actions.getImages(yachtImages));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setRealImages(id, isRealImages) {
  return async (dispatch) => {
    try {
      await axios.put(API_ROUTES.yachts.setRealImages(id), { real_images: isRealImages });
      dispatch(updateYachtImageType(isRealImages));
    } catch (error) {
      console.log(error);
    }
  };
}

export function uploadImages(id, images, hasImages) {
  return async (dispatch) => {
    dispatch(slice.actions.startUploading());

    try {
      if (images.length) {
        // create formData object
        const formData = new FormData();
        images.forEach((file, index) => {
          // if there's no images, set first image as main
          if (!hasImages && index === 0) {
            formData.append('yacht[images][][main]', true);
          } else {
            formData.append('yacht[images][][main]', false);
          }
          formData.append('yacht[images][][image]', file);
          formData.append('yacht[images][][rotation]', file.rotate || 0);
        });

        const { data } = await axios.put(API_ROUTES.yachts.uploadImages(id), formData, {
          'Content-Type': 'multipart/form-data'
        });

        dispatch(slice.actions.successUpload(data.yachtImages));
        if (!hasImages) {
          const [firstImage] = data.yachtImages;
          dispatch(updateYachtMainImage(firstImage.large));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteImage(image) {
  return async (dispatch) => {
    try {
      const {
        data: { yachtImages }
      } = await axios.delete(API_ROUTES.images.delete(image.id));

      dispatch(slice.actions.deleteImageSuccess(yachtImages));
    } catch (error) {
      console.log({ error });
      dispatch(slice.actions.imageError(error));
    }
  };
}

export function setAsMain(image) {
  return async (dispatch) => {
    try {
      const {
        data: { yachtImages }
      } = await axios.put(API_ROUTES.images.setAsMain(image.id));

      dispatch(slice.actions.setAsMainImageSuccess(yachtImages));
      // reorder images to set main as first
      const [firstImage] = yachtImages;
      if (firstImage.id !== image.id) {
        dispatch(sortImages(yachtImages, 1, image.id));
      }
    } catch (error) {
      console.log({ error });
      dispatch(slice.actions.imageError(error));
    }
  };
}

export function uploadMainImage(id, image) {
  return async (dispatch) => {
    dispatch(slice.actions.startUploading());
    try {
      if (image) {
        // create formData object
        const formData = new FormData();
        formData.append('yacht[images][][main]', true);
        formData.append('yacht[images][][image]', image);

        const {
          data: { yachtImages }
        } = await axios.put(API_ROUTES.yachts.uploadMainImage(id), formData, {
          'Content-Type': 'multipart/form-data'
        });

        dispatch(slice.actions.successUpload(yachtImages));
        const mainImage = yachtImages.find((img) => img.main);
        dispatch(updateYachtMainImage(mainImage.large));
      }
    } catch (error) {
      console.log('@@@', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function sortImages(images, position, imageId) {
  return async (dispatch) => {
    dispatch(slice.actions.updateImages(images));

    try {
      const {
        data: { yachtImages }
      } = await axios.put(API_ROUTES.images.reorder(imageId), { position });

      dispatch(slice.actions.updateImages(yachtImages));
      // always set as main first image
      const [firstImage] = yachtImages;
      if (!firstImage.main) {
        dispatch(setAsMain(firstImage));
        dispatch(updateYachtMainImage(firstImage.large));
      }
    } catch (error) {
      console.log('@@@', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clear() {
  return async (dispatch) => {
    dispatch(slice.actions.clearImages());
  };
}

export function reset() {
  return async (dispatch) => {
    dispatch(slice.actions.reset());
  };
}
