import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { API_ROUTES } from 'src/routes/api';
// ----------------------------------------------------------------------

export const MODAL_TYPE = {
  CANCEL_RESERVATION: 'CANCEL_RESERVATION',
  CHANGE_RESERVATION_SOURCE: 'CHANGE_RESERVATION_SOURCE',
  EDIT_CLIENT_DATA: 'EDIT_CLIENT_DATA',
  PRICE_DETAILS: 'PRICE_DETAILS',
  CONFIRM_PAYMENT: 'CONFIRM_PAYMENT',
  PREPAYMENT_DETAILS: 'PREPAYMENT_DETAILS',
  NOTES: 'NOTES',
  ADDIDIONAL_FEES: 'ADDIDIONAL_FEES'
};

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: false,
  reservation: {},
  clientData: {},
  availableReservationSources: [],
  activeModal: {
    type: null,
    data: null
  }
};

const slice = createSlice({
  name: 'reservationDetails',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET RESERVATION
    getReservationSuccess(state, action) {
      state.isLoading = false;
      state.isLoaded = true;
      state.reservation = action.payload;
    },

    getClientData(state, action) {
      state.isLoading = false;
      state.isLoaded = true;
      state.clientData = action.payload;
    },

    clearReservation(state) {
      state.reservation = {};
    },

    setAvailableReservationSources(state, action) {
      state.availableReservationSources = action.payload;
    },

    setActiveModal(state, action) {
      state.activeModal = {
        type: action.payload?.type || null,
        data: action.payload?.data || null
      };
    },

    reset(state) {
      Object.keys(state).forEach((key) => (state[key] = initialState[key]));
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { clearReservation, setActiveModal } = slice.actions;

// ----------------------------------------------------------------------

export function getReservationDetails(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const {
        data: { reservation, availableReservationSources }
      } = await axios.get(API_ROUTES.reservations.by_index(id));

      dispatch(slice.actions.getReservationSuccess(reservation));
      dispatch(slice.actions.setAvailableReservationSources(availableReservationSources));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function reset() {
  return async (dispatch) => {
    dispatch(slice.actions.reset());
  };
}

export function getClientData(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(API_ROUTES.clients.by_index(id));
      dispatch(slice.actions.getClientData(response.data.client));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
