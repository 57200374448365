// material
import { useTheme } from '@mui/material/styles';
import { GlobalStyles as GlobalThemeStyles } from '@mui/material';

// ----------------------------------------------------------------------

export default function GlobalStyles() {
  const theme = useTheme();

  return (
    <GlobalThemeStyles
      styles={{
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box'
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch'
        },
        body: {
          width: '100%',
          height: '100%'
        },
        '#root': {
          width: '100%',
          height: '100%'
        },
        '.MuiCard-root.warning': {
          background: theme.palette.warning.main
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none'
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none'
            }
          }
        },
        textarea: {
          '&::-webkit-input-placeholder': {
            color: theme.palette.text.disabled
          },
          '&::-moz-placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled
          },
          '&:-ms-input-placeholder': {
            color: theme.palette.text.disabled
          },
          '&::placeholder': {
            color: theme.palette.text.disabled
          }
        },

        img: { display: 'block', maxWidth: '100%' },

        // Lazy Load Img
        '.blur-up': {
          WebkitFilter: 'blur(5px)',
          filter: 'blur(5px)',
          transition: 'filter 400ms, -webkit-filter 400ms'
        },
        '.blur-up.lazyloaded ': {
          WebkitFilter: 'blur(0)',
          filter: 'blur(0)'
        },

        // Fullcalendar

        '.fc-timeline-slot-label': {
          color: '#637381'
        },
        '.fc table': {
          fontSize: '16px !important'
        },
        '.fc-timeline-slot': {
          fontSize: '10px'
        },
        '.fc-event-title.fc-sticky': {
          position: 'static'
        },
        '.fc .fc-button-primary': {
          backgroundColor: '#0077B6 !important',
          border: 'none !important'
        },
        '.fc .fc-button-primary.fc-button-active': {
          backgroundColor: '#005a8a !important'
        },
        'th .fc-day-sat, th .fc-slot-sat, .fc-resourceTimelineSesson-view .fc-slot-sat': {
          backgroundColor: '#3788d829'
        },
        'th .fc-day-sun, th .fc-slot-sun, .fc-resourceTimelineSesson-view .fc-slot-sun': {
          backgroundColor: '#3788d849'
        },
        /* td.fc-timeline-slot:nth-child(2n) { border: 0!important; } */
        '.fc .fc-timeline-event': {
          borderRadius: '50px !important',
          height: '30px',
          padding: '2px !important'
        },
        '.fc .fc-timeline-events': {
          paddingBottom: '1px !important'
        },
        '.fc .fc-timeline-header-row-chrono .fc-timeline-slot-frame': {
          justifyContent: 'center !important'
        },
        '.fc .fc-timeline-event-harness': { marginTop: '2px !important' },
        '.fc .fc-timeline-overlap-enabled .fc-timeline-lane-frame .fc-timeline-events': { paddingBottom: '2px' },

        '.fc-timeline-event:not(.fc-event-end):after, .fc-timeline-event:not(.fc-event-start):before': {
          border: '8px solid #000',
          margin: '0 2px',
          borderTopColor: 'transparent',
          borderBottomColor: 'transparent'
        },

        '.fc-datagrid-cell.fc-resource .fc-datagrid-cell-main': {
          fontSize: '12px',
          display: 'block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          marginTop: '0px',
          lineHeight: '18px'
        },
        '.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr': {
          '@media(max-width: 860px)': {
            flexDirection: 'column',
            gap: '8px',

            '.fc-toolbar-chunk:last-of-type': {
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              gap: '4px',
              flexWrap: 'wrap'
            }
          }
        },
        '&.ck.ck-balloon-panel': {
          zIndex: `${theme.zIndex.modal} !important`,
        }
      }}
    />
  );
}
