// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();

  const { color, name } = createAvatar(user.name || user.nickname || user.email);

  return (
    <MAvatar
      // src={user.photoURL}
      // alt={user.displayName}
      color={color}
      {...other}
    >
      {name}
    </MAvatar>
  );
}
