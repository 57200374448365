import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { styled } from '@mui/material/styles';
import { Box, Stack, Container, IconButton, Typography } from '@mui/material';
// hooks
// components
import { MHidden } from '../../components/@material-extend';
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import { useDispatch } from 'src/redux/store';
import { setIsOpenSidebar } from 'src/redux/slices/ui';
import useAuth from 'src/hooks/useAuth';
import SwitchAccountPopover from './SwitchAccountPopover';
import { ROLES } from 'src/contexts/JWTContext';

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '12px 0',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    padding: '30px 0'
  }
}));

// ----------------------------------------------------------------------

export default function DashboardNavbar({ title, header }) {
  const dispatch = useDispatch();
  const { user } = useAuth();

  const onOpenSidebar = () => dispatch(setIsOpenSidebar(true));

  return (
    <Container maxWidth={false}>
      <RootStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        {header || (
          <Typography color="black" variant="h4">
            {title}
          </Typography>
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {user?.role >= ROLES.manager ? <SwitchAccountPopover /> : null}
          <Searchbar />
          <AccountPopover />
        </Stack>
      </RootStyle>
    </Container>
  );
}
