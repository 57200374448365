import axios from 'axios';
import { setToken } from './jwt';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

const handleAccessToken = (response) => {
  const accessToken = response?.headers?.['access-token'];
  if (accessToken) {
    setToken(accessToken);
  }
};

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers['access-token'] = localStorage.getItem('accessToken');

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    handleAccessToken(response);

    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      return Promise.reject(error);
    }

    console.log(error.response && error.response.data);

    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;
