import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { API_ROUTES } from 'src/routes/api';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  priceListItems: [],
  availableRestrictions: [],
  priceListSettings: {}
};

const slice = createSlice({
  name: 'priceList',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRICE LIST
    getPriceListSuccess(state, action) {
      state.isLoading = false;
      state.priceListItems = action.payload.priceListItems;
      state.priceListSettings = action.payload.priceListSettings;
      state.availableRestrictions = action.payload.availableRestrictions;
    },

    reset(state) {
      Object.keys(state).forEach((key) => (state[key] = initialState[key]));
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getPriceList(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const {
        data: { priceList, availableRestrictions }
      } = await axios.get(API_ROUTES.priceLists.by_index(id));
      const { priceListItems, ...priceListSettings } = priceList;

      const payload = {
        priceListItems,
        priceListSettings,
        availableRestrictions
      };

      dispatch(slice.actions.getPriceListSuccess(payload));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function reset() {
  return async (dispatch) => {
    dispatch(slice.actions.reset());
  };
}
