const V = 'v1';
const BASE = `/${V}/internal`;

export const API_ROUTES = {
  auth: {
    singIn: `${BASE}/auth/sign_in`,
    password: `${BASE}/auth/password`
  },
  users: {
    profile: `${BASE}/users/profile`,
    togglePartner: `${BASE}/users/toggle_partner_yachts_online_booking`
  },
  yachts: {
    list: `${BASE}/yachts`,
    create: `${BASE}/yachts`,
    activate: `${BASE}/yachts/activate`,
    deactivate: `${BASE}/yachts/deactivate`,
    by_index: (id) => `${BASE}/yachts/${id}`,
    search: `${BASE}/yachts/search`,
    clone: (id) => `${BASE}/yachts/${id}/clone`,
    labels: `${BASE}/yachts/labels`,
    labels_by_index: (id) => `${BASE}/yachts/${id}/labels`,
    new: `${BASE}/yachts/new`,
    available: `${BASE}/yachts/available`,
    book: (id) => `${BASE}/yachts/${id}/book_it`,
    gallery: (id) => `${BASE}/yachts/${id}/gallery`,
    uploadImages: (id) => `${BASE}/yachts/${id}/upload_images`,
    uploadMainImage: (id) => `${BASE}/yachts/${id}/upload_main_image`,
    promotions: (id) => `${BASE}/yachts/${id}/price_cuts`,
    reservations: (id) => `${BASE}/yachts/${id}/reservations`,
    setRealImages: (id) => `${BASE}/yachts/${id}/set_real_images`,
    cloneGallery: (id) => `${BASE}/yachts/${id}/clone_gallery`
  },
  images: {
    setAsMain: (id) => `${BASE}/yacht_images/${id}/set_as_main`,
    delete: (id) => `${BASE}/yacht_images/${id}`,
    reorder: (id) => `${BASE}/yacht_images/${id}/reorder`
  },
  yachtTypes: {
    specification: (id) => `${BASE}/yacht_types/${id}/specification`
  },
  priceLists: {
    copyToYachts: (id) => `${BASE}/price_lists/${id}/copy_to_yachts`,
    by_index: (id) => `${BASE}/price_lists/${id}`
  },
  priceListItems: {
    index: `${BASE}/price_list_items`,
    by_index: (id) => `${BASE}/price_list_items/${id}`,
    bulkDestroy: `${BASE}/price_list_items/bulk_destroy`
  },
  marinas: {
    search: `${BASE}/marinas/search`,
    setOnYachts: (id) => `${BASE}/marinas/${id}/set_on_yachts`
  },
  reservations: {
    list: `${BASE}/reservations`,
    by_index: (id) => `${BASE}/reservations/${id}`,
    finish: (id) => `${BASE}/reservations/${id}/finish`,
    changeSource: (id) => `${BASE}/reservations/${id}/change_source`,
    sendNotification: (id) => `${BASE}/reservations/${id}/send_notification`,
    matrix: `${BASE}/reservations/matrix`,
    changePrice: (id) => `${BASE}/reservations/${id}/change_price`,
    changePrepayment: (id) => `${BASE}/reservations/${id}/change_prepayment`,
    calculateFinalPrice: (id) => `${BASE}/reservations/${id}/calculate_final_price`,
    cancel: (id) => `${BASE}/reservations/${id}/cancel`,
    expire: (id) => `${BASE}/reservations/${id}/expire`,
    confirmPayment: (id) => `${BASE}/payments/${id}/confirm`,
    confirmReservation: (id) => `${BASE}/reservations/${id}/confirm`,
    sendConfirmationNotification: (id) => `${BASE}/reservations/${id}/send_confirmation_notification`,
    generateCharterAgreement: (id) => `${BASE}/reservations/${id}/generate_charter_agreement`,
    markAsMine: (id) => `${BASE}/reservations/${id}/mark_as_mine`,
    updateNotes: (id) => `${BASE}/reservations/${id}/update_notes`,
    changeDatesOrYacht: (id) => `${BASE}/reservations/${id}/change_dates_or_yacht`,
    changeAdditionalFees: (id) => `${BASE}/reservations/${id}/change_additional_fees`
  },
  clients: {
    list: `${BASE}/clients`,
    by_index: (id) => `${BASE}/clients/${id}`
  },
  templates: {
    index: `${BASE}/document_templates`,
    by_index: (id) => `${BASE}/document_templates/${id}`
  },
  partners: {
    search: (query) => `${BASE}/partners/search?q=${query}`
  },
  settlements: {
    index: `${BASE}/settlements`,
    orderPayout: `${BASE}/settlements/order_payout`
  },
  promotions: {
    index: `${BASE}/price_cuts`,
    byId: (id) => `${BASE}/price_cuts/${id}`
  },
  reports: {
    bosuns: `${BASE}/reports/bosuns_report`,
    cleaningService: `${BASE}/reports/cleaning_service_report`
  },
  specialOffers: {
    matrix: `${BASE}/special_offers/matrix`,
    create: `${BASE}/special_offers`,
    update: (id) => `${BASE}/special_offers/${id}`, // PUT
    offer: (id) => `${BASE}/special_offers/${id}`, // GET
    delete: (id) => `${BASE}/special_offers/${id}`, // DELETE
    baseData: (id, start, end) => `${BASE}/special_offers/pattern?yacht_id=${id}&date_from=${start}&date_to=${end}`
  },
  filters: `${BASE}/filters`
};
