import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { API_ROUTES } from 'src/routes/api';

// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  error: false,
  marinasList: []
};

const slice = createSlice({
  name: 'marinas',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET DATA
    getMarinas(state, action) {
      state.isLoading = false;
      state.marinasList = action.payload;
    },

    reset(state) {
      Object.keys(state).forEach((key) => (state[key] = initialState[key]));
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getMarinas() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const {
        data: { marinas }
      } = await axios.get(API_ROUTES.marinas.search);
      dispatch(
        slice.actions.getMarinas(
          marinas.map(({ id, name, ...rest }) => ({
            label: name,
            value: id,
            ...rest
          }))
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function reset() {
  return async (dispatch) => {
    dispatch(slice.actions.reset());
  };
}
