import { createSlice } from '@reduxjs/toolkit';
// utils
import { reset as blog } from 'src/redux/slices/blog';
import { reset as calendar } from 'src/redux/slices/calendar';
import { reset as clients } from 'src/redux/slices/clients';
import { reset as gallery } from 'src/redux/slices/gallery';
import { reset as marinas } from 'src/redux/slices/marinas';
import { reset as priceList } from 'src/redux/slices/priceList';
import { reset as product } from 'src/redux/slices/product';
import { reset as reservations } from 'src/redux/slices/reservations';
import { reset as reservationDetails } from 'src/redux/slices/reservationDetails';
import { reset as reservationYachts } from 'src/redux/slices/reservationYachts';
import { reset as yachtForm } from 'src/redux/slices/yachtForm';
import { reset as yachts } from 'src/redux/slices/yachts';

const initialState = {};

const slice = createSlice({
  name: 'root',
  initialState,
  reducers: {}
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

const STORES_TO_BE_RESET = {
  blog: blog,
  calendar: calendar,
  clients: clients,
  gallery: gallery,
  marinas: marinas,
  priceList: priceList,
  product: product,
  reservationDetails: reservationDetails,
  reservations: reservations,
  reservationYachts: reservationYachts,
  yachtForm: yachtForm,
  yachts: yachts
};

export function resetStores() {
  return async (dispatch, getState) => {
    try {
      Object.keys(STORES_TO_BE_RESET).forEach((store) => {
        dispatch(STORES_TO_BE_RESET[store]());
      });
    } catch (error) {
      // // eslint-disable-next-line no-console
      console.log('@ error resetting stores', error);
    }
  };
}
