import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import uiReducer from './slices/ui';
import userReducer from './slices/user';
import yachtsReducer from './slices/yachts';
import yachtFormReducer from './slices/yachtForm';
import priceListReducer from './slices/priceList';
import marinasReducer from './slices/marinas';
import reservationsReducer from './slices/reservations';
import reservationDetailsReducer from './slices/reservationDetails';
import reservationYachtsReducer from './slices/reservationYachts';
import reservationClientsReducer from './slices/clients';
import templatesReducer from './slices/templates';

import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import galleryReducer from './slices/gallery';
import rootSliceReducer from './slices/root';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  ui: uiReducer,
  user: userReducer,
  yachts: yachtsReducer,
  yachtForm: yachtFormReducer,
  priceList: priceListReducer,
  marinas: marinasReducer,
  reservations: reservationsReducer,
  reservationDetails: reservationDetailsReducer,
  reservationYachts: reservationYachtsReducer,
  clients: reservationClientsReducer,
  templates: templatesReducer,
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  gallery: galleryReducer,
  root: rootSliceReducer
});

export { rootPersistConfig, rootReducer };
