import { useTranslation } from 'react-i18next';
// material
import { plPL } from '@mui/material/locale';
import { setLocale } from 'yup';
import yupLocalePL from "yup-locale-pl";

setLocale(yupLocalePL);

// ----------------------------------------------------------------------

const LANGS = [
  {
    label: 'Polski',
    value: 'pl',
    systemValue: plPL,
    icon: '/static/icons/ic_flag_en.svg'
  },
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[0];

  const handleChangeLanguage = (newlang) => {
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS
  };
}
