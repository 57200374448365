import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { forwardRef, useEffect, useCallback } from 'react';
// material
import { Box } from '@mui/material';
// utils
import track from '../utils/analytics';
import DashboardNavbar from 'src/layouts/dashboard/DashboardNavbar';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, header, title = '', withNavbar = true, ...other }, ref) => {
  const { isAuthenticated } = useAuth();

  const { pathname } = useLocation();

  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: pathname
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  return (
    <Box ref={ref} {...other}>
      <Helmet>
        <title>{title} | yachtCMS</title>
      </Helmet>
      {withNavbar && isAuthenticated && <DashboardNavbar title={title} header={header} />}
      {children}
    </Box>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;
